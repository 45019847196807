<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoFoodMeter",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoFoodMeter",
        mrLevel: {},
        category: {},
        statusRecipe: {},
        mpTitleLength:0,
        mrCatAll:[],
        addCategory:false,
        input:{}
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      addCat(){
        this.addCategory=true
        this.input.mfc_is_active = 'Y'
        this.input.type = 'addCat'
      },
      editCat(v){
        this.input = v
        this.addCategory=true
        this.input.type = 'editCat'
      },
      cancel(){
        this.input={}
        this.addCategory=false
      },
      submitFormCat(e) {
        if(e&&e.btnLoading()) return;
        BOGen.apirest('/' + this.Name, this.input, (err, resp) => {
          if(e) e.btnUnloading()
          if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.col-info')
          if (resp.success) {
            Gen.info(resp.message, 'success', 2000, '.col-info')
            this.refreshData()
            setTimeout(() => {
              this.input={}
              this.cancel()
            }, 1000)
          }
        }, "POST");
     },
    },
    watch: {
      '$route.query'() {
        if(this.$route.params.id){
          this.$set(this.row, 'category', this)
          this.$set(this.row, 'statusRecipe', this)
          this.$set(this.row, 'search', this)
          this.$set(this.row, 'page', this)
          setTimeout(()=>{
            this.row.category = this.$route.query.category
            this.row.statusRecipe = this.$route.query.statusRecipe
            this.row.search = this.$route.query.search
            this.row.page = this.$route.query.page
          },500)
        }
        this.refreshData()
      },
      'filter.category'(v) {
        if (!v) return
        this.search()
      },
      'filter.status'(v) {
        if (!v) return
        this.search()
      },
      'row.mr_title'(v) {
        if (!v) return
        this.mpTitleLength=v.length
      },
    }
  };
</script>

<template>
  <div class="container-fluid">
    <!-- <PageTitle></PageTitle> -->
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h4 class="text-themecolor">{{page.PageName||"-"}}</h4>
      </div>
      <div class="col-md-7 align-self-center text-right">
        <div class="d-flex justify-content-end align-items-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link :to="{name:'BoDashboard'}">Home</router-link></li>
            <li v-if="page.PageName != 'Home'" class="breadcrumb-item">{{page.PageName||"-"}}</li>
            <li v-if="page.PageName != 'Home'" class="breadcrumb-item active">{{page.ObjectName||"-"}}</li>
          </ol>
          <router-link v-if="page.Name && $route.params.id" :to="{name:page.Name}" class="btn btn-success d-none d-lg-block m-l-15">
            <i class="fas fa-list-ul m-r-15"></i>List {{page.ObjectName}}
          </router-link>
        </div>
      </div>
    </div>

<div class="row" v-if="!$route.params.id">
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
       <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h5 class="card-title">Category Foodmeter</h5>
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
          <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th width="120px">Action</th>
                </tr>
              </thead>
              <tbody v-if="mrCatAll.length > 0">
                <tr v-for="(v,k) in mrCatAll" :key="k" :id="k">
                  <td class="number">{{(k+1)}}</td>
                  <td>{{v.mfc_name}}</td>
                  <td><StatusLabel :status="v.status"></StatusLabel></td>
                  <td class="btn-action">
                    <a v-if="page.moduleRole('Edit')" class="icon_action" href="javascript:;" v-tooltip="'Edit'" @click="editCat(v)"><i class="ti-marker-alt"></i></a>
                    <a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action" @click="deleteItemId(v.mfc_id,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>                    
                <tr>
                  <td colspan="99">
                    <h3 class="tc">No Result</h3>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="table-responsive">
            <div v-if="!addCategory" class="text-right col-md-12 mb-3"> <a href="javascript:;" @click="addCat" class="btn btn-success m-l-15"><i class="fa fa-plus-circle  m-r-15"></i>Add Category</a></div>
            <VForm @resp="submitFormCat" method="post">
            <div class="col-info"></div>
            <table class="table table-bordered" v-if="addCategory">
                <thead>
                  <tr>
                    <th>Category Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody >
                  <tr>
                    <td>
                      <div class="form-group">
                        <input name="mfc_name" v-model="input.mfc_name" required="" onkeydown="" type="text" placeholder="Category Name" class="form-control">
                      </div>
                    </td>
                    <td>
                        <div class="row mt-2">
                          <radio name="mfc_is_active" v-model="input.mfc_is_active" option="Y"  :attr="validation('mfc_is_active')">Active</radio>
                          <radio name="mfc_is_active" v-model="input.mfc_is_active" option="N">Inactive</radio>
                        </div>
                    </td>
                    <td align="center" width="20%">
                        <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading m-r-15">Submit 
                          <i class="icon-arrow-right14 position-right"></i>
                        </button>
                        <a href="javascript:;" @click="cancel" class="fcbtn btn btn-warning btn-outline btn-1e">Cancel 
                          <i class="icon-arrow-right14 position-right"></i>
                        </a>
                    </td>
                  </tr>
                </tbody>
            </table>
          </VForm>
          </div>
    </div>
  </div>
</div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header" v-if="!$route.params.id">
            <VForm @resp="search">
              <div class="row">
                <div class="col-sm-2">
                  <h5 class="card-title">Food Meter List</h5>
                </div>
                <div class="col-sm-2">
                  <select2 :options="mrCat" :object="['mfc_id','mfc_name']" v-model="filter.category">
                    <option value="">-- Select Category --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <select2 :options="status" v-model="filter.status">
                    <option value="">-- Select Status --</option>
                  </select2>
                </div>
                <div class="col-sm-3">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                        placeholder="Search...">
                      <div class="input-group-append">
                        <button class="btn btn-info" type="button" @click="search()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-1">
                  <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                </div>
                <div class="col-md-2">
                    <router-link v-if="page.AddItem && !$route.params.id && page.moduleRole('Add')" :to="{name:page.Name,params:{id:'add'}}" class="btn btn-info d-none d-lg-block m-l-15"> <i class="fa fa-plus-circle  m-r-15"></i>Add Food Meter </router-link>
                </div>
              </div>
            </VForm>
          </div>
          <div class="card-body">

            <div class="row" v-if="!$route.params.id">
              <div class="col-12">
                <div class="alert alert-info">
                  Image Requirements for Food Meter :
                  <ul class="mb-0 mt-2">
                    <li><strong>Dimension: </strong><span>507x422px</span></li>
                    <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
                    <li><strong>Max. Size: </strong><span>2 MB</span></li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-3 mb-3" v-for="(v,k) in data.data" :key="k">
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2">
                            <img v-if="!v.mcf_img" :src="assets('fo_images','default-img.jpg')" alt="prod">
                            <img v-else :src="uploader(v.mcf_img,'250')" alt="prod">
                            <div class="pro-img-overlay">
                              <router-link class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}" v-tooltip="'Edit'"><i
                                  class="ti-marker-alt"></i></router-link>
                              <a href="javascript:;" class="bg-danger" @click="deleteItem($event,k)"
                                v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                            </div>
                            <span class="label label-success" v-if="v.mcf_is_active=='Y'">Active</span>
                            <span class="label label-danger" v-else>Inactive</span>
                          </div>
                          <small class="text-danger mt-2">{{v.mfc_name}}</small>
                          <h5 class="card-title mt-1">{{v.mcf_menu}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="NotFound" class="text-center col-md-12">
                  <h5 class="tc">{{NotFound}}</h5>
                </div>
                <div v-if="data.data===false" class="text-center col-md-12">
                  <LoadingSpinner light></LoadingSpinner>
                </div>
                <div class="panel-body">
                  <div class="pull-right">
                    <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="$route.params.id">
              <div class="col-12">
                <VForm @resp="submitForm" method="post">
                  <div class="card-body">
                    <div class="row mb-3">
                      <div class="col-md-8">
                        <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                      </div>
                    </div>
                    <div class="info"></div>
                    <div class="row">
                      <div class="col-sm-7">
                        <div class="alert alert-info">
                          <strong>*Untuk memasukkan angka desimal, gunakan titik (.) <br>Contoh: 1.23</strong>
                        </div>
                        <BoField name="mcf_menu" v-model="row.mcf_menu"></BoField>
                        <BoField name="mcf_cat_id">
                          <select2 name="mcf_cat_id" :placeholder="'Select Category'" v-bind="validation('mcf_cat_id')"  :options="MrCategory"
                            :object="['mfc_id','mfc_name']" v-model="row.mcf_cat_id">
                          </select2>
                        </BoField>
                        <div class="row">
                          <div class="col-md-6">
                            <BoField name="mcf_porsi" onkeydown="return numberKeyComma(event)" :attr="{placeholder:'ex: 123.45'}" v-model="row.mcf_porsi"></BoField>
                            <BoField name="mcf_calorie" onkeydown="return numberKeyComma(event)" :attr="{placeholder:'ex: 123.45'}" v-model="row.mcf_calorie"></BoField>
                            <BoField name="mcf_note" :attr="{placeholder:'ex: (1 Cup)'}" v-model="row.mcf_note"></BoField>
                            <BoField name="mcf_protein" onkeydown="return numberKeyComma(event)" :attr="{placeholder:'ex: 123.45'}" v-model="row.mcf_protein"></BoField>  
                          </div>
                          <div class="col-md-6">
                            <BoField name="mcf_fat" onkeydown="return numberKeyComma(event)" :attr="{placeholder:'ex: 123.45'}" v-model="row.mcf_fat"></BoField>
                              <BoField name="mcf_karbo" onkeydown="return numberKeyComma(event)" :attr="{placeholder:'ex: 123.45'}" v-model="row.mcf_karbo"></BoField>
                              <BoField name="mcf_gula" onkeydown="return numberKeyComma(event)" :attr="{placeholder:'ex: 123.45'}" v-model="row.mcf_gula"></BoField>
                              <div class="row">
                                <div class="col-8">
                                  <BoField name="mcf_is_active">
                                    <div class="row">
                                      <radio name="mcf_is_active" v-model="row.mcf_is_active" option="Y"
                                        :attr="validation('mcf_is_active')">Active</radio>
                                      <radio name="mcf_is_active" v-model="row.mcf_is_active" option="N">Inactive</radio>
                                    </div>
                                  </BoField>
                                </div>
                              </div>
                          </div>
                        </div>     
                      </div>
                      <div class="col-sm-5">
                        <BoField name="mcf_img">
                          <Uploader name="mcf_img" type="food" uploadType="cropping" :param="{thumbnail:true}" v-model="row.mcf_img"></Uploader>
                        </BoField>
                      </div>
                      
                      <div class="col-sm-12">
                        <div class="text-right">
                          <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form
                            <i class="icon-arrow-right14 position-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </VForm>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>